import React from "react"
import LayoutB1 from "../../components/layout-b1"
import './search.css'

export default class Search extends React.Component {
  render() {
    return(
      <LayoutB1 location={this.props.location} >
          <div className="search-top">
            <div className="search-input-container">
              <img src={require('../../images/b1/search.svg')} alt="搜索"/>
              <input placeholder="请输入你想搜索的内容"/>
            </div>
            <div className="search-btn">搜索</div>
          </div>
          <div className="search-tips">
            <div>赛事</div>
            <div>咨询</div>
            <div>装备</div>
          </div>
          <div className="search-result">

          </div>
      </LayoutB1>
    )
  }
}

